import {MDCFloatingLabel} from '@material/floating-label';
import {MDCTextField} from '@material/textfield';
import {MDCDialog} from '@material/dialog';
import {MDCLinearProgress} from '@material/linear-progress';
import {MDCTabBar} from '@material/tab-bar';
import {MDCRipple} from '@material/ripple/index';
import {MDCList} from "@material/list";
import {MDCTopAppBar} from '@material/top-app-bar';
import {MDCDrawer} from '@material/drawer';
import {MDCDataTable} from '@material/data-table';
import {MDCMenu} from '@material/menu';
import {MDCSelect} from '@material/select';
import {MDCTooltip} from '@material/tooltip';
import {MDCChipSet} from '@material/chips';
import {mdcAutoInit, MDCAttachable} from '@material/auto-init';

// DOMContentLoaded sicherstellen, dass das DOM vollständig geladen ist
//document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.mdc-floating-label').forEach(e1 => {
        MDCFloatingLabel.attachTo(e1);
    });

    document.querySelectorAll('.mdc-text-field').forEach(e1 => {
        MDCTextField.attachTo(e1);
    });

    document.querySelectorAll('.mdc-dialog').forEach(e1 => {
        const dialog = MDCDialog.attachTo(e1);
        dialog.listen('MDCDialog:opened', () => {
            // contentElement.setAttribute('aria-hidden', 'true');
        });
        dialog.listen('MDCDialog:closing', () => {
            // contentElement.removeAttribute('aria-hidden');
        });
        dialog.close();
    });

	document.querySelectorAll('.mdc-tab-bar').forEach(e1 => {
		const tabBar=MDCTabBar.attachTo(e1);
		tabBar.listen('MDCTabBar:activated', function(event: CustomEvent) {
			const tabBarId=e1.id;
			const index=event.detail.index;
			// console.log("tab "+tabBarId+", no:"+index);
			[].forEach.call(document.querySelectorAll(`div[data-tabbarid="${tabBarId}"]`), function(tab:HTMLElement) {
				var tabno=tab.dataset.tabno;
				if(tabno == index){
					tab.style.display = "block";
				}else{
					tab.style.display = "none";
				}
			})
		})  
	});

    document.querySelectorAll('.mdc-button').forEach(e1 => {
        MDCRipple.attachTo(e1);
    });

    document.querySelectorAll('.mdc-list').forEach(e1 => {
        const list = MDCList.attachTo(e1);
        list.listElements.map(listItemEl => new MDCRipple(listItemEl));
    });

    const topAppBarElement = document.querySelector('.mdc-top-app-bar'); 
    if(topAppBarElement) {
        const topAppBar = new MDCTopAppBar(topAppBarElement);
        document.querySelectorAll('.mdc-drawer').forEach(e1 => {
            const drawer = MDCDrawer.attachTo(e1);
            topAppBar.listen('MDCTopAppBar:nav', () => {
                drawer.open = !drawer.open;
            });
        });
    }

    document.querySelectorAll('.mdc-data-table').forEach(e1 => {
        MDCDataTable.attachTo(e1);
    });

    document.querySelectorAll('.mdc-menu').forEach(e1 => {
        MDCMenu.attachTo(e1);
    });

    document.querySelectorAll('.mdc-select').forEach(e1 => {
        const select = MDCSelect.attachTo(e1);
        select.listen('MDCSelect:change', () => {
            alert(`Selected option at index ${select.selectedIndex} with value "${select.value}"`);
        });
    });

    document.querySelectorAll('.mdc-tooltip').forEach(e1 => {
        MDCTooltip.attachTo(e1);
    });

    document.querySelectorAll('.mdc-chip-set').forEach(e1 => {
        MDCChipSet.attachTo(e1);
    });

    // Auto-init
    mdcAutoInit.register('MDCMenu', MDCMenu as MDCAttachable);
    // https://github.com/material-components/material-components-web/tree/master/packages/mdc-linear-progress
	// make sure to use same key as in MDCHandle.
	mdcAutoInit.register('MDC', MDCLinearProgress as MDCAttachable);
    mdcAutoInit();

//});
