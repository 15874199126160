<template>
	<div class="mdc-elevation--z1 "><div class="mdc-layout-grid">
		<div class="mdc-layout-grid__inner"><div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3"></div>
		<div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-9"><h5 class="mdc-typography--headline5">{{blogEntry.title}}</h5>
		<br><h1 class="mdc-typography--subtitle1">{{blogEntry.date}} by {{blogEntry.author}}</h1>
		</div></div>
		<div class="mdc-layout-grid__inner"><div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3"><img class="blog-author" @error="(event)=> event.target.style.visibility ='hidden'" style="max-height:110px;max-width:110px;visibility:hidden;width:110px;height:auto" v-bind:src="blogEntry.avatarRef" @load="(event)=> event.target.style.visibility ='visible'"></div>
		<div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-9"><span v-html="blogEntry.text"></span></div></div>
		<div class="mdc-layout-grid__inner"><div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-3-tablet mdc-layout-grid__cell--span-0-phone"></div>
		<div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-9-desktop mdc-layout-grid__cell--span-9-tablet mdc-layout-grid__cell--span-12-phone"><CarouselVC v-bind:caruselItems="blogEntry.media" v-bind:p_instanceId="'id_37'" v-bind:p_vueDomain="c_vueDomain+'.'+'CarouselVC'"><template v-slot:carousel="media"><span v-show="media.media.thetype=='image/png'"><img @error="(event)=> event.target.style.visibility ='hidden'" style="visibility:hidden;width:400px;height:200px" v-bind:src="media.media.source" @load="(event)=> event.target.style.visibility ='visible'"></span>
		<span v-show="media.media.thetype=='map'">TODO MAP</span>
		<span v-show="media.media.thetype=='video'"><video class="blogSlide" preload="none" loop muted autoplay><source v-bind:src="media.media.source" type="video/mp4">
		Your browser does not support the video tag.</video></span>
		</template></CarouselVC></div></div>
	</div>
	</div>
</template>

<script>
	// <script lang="ts">
	import $ from 'jquery';
	// see de.vdoemming.blog.vue.components.blog.BlogEntryVC
	import CarouselVC from './carouselvc.vue'
	export default {
		props: {
			p_vueDomain: {
						type: String,
						required: false
					},
			ddeebbuugg: {
						type: Object,
						required: false,
						default: {}
					},
			blogEntry: {
						type: Object,
						required: true
					},
			p_instanceId: {
						type: String,
						required: false
					}
		}
		,
		data() {
			return {}
		}
		,
		computed: {
			c_vueDomain() {
				if(this.p_vueDomain==undefined){
					return 'BlogEntryVC'
				}else{
					return this.p_vueDomain
				}
			}
			,
			console() {
				return console
			}
		}
		,
		components: {
			CarouselVC
		}
		,
		methods: {
			i18n: function(key){
				var result=this.globalI18nDict[key];
				if(result===undefined){
					return key
				}else{
					return result
				}
			}
		}
		,
		mounted() {
			const thisVue=this
			
			console.log('mounted component on vue domain: '+this.c_vueDomain)
		}
		,
		provide() {
			return {}
		}
	}
</script>
