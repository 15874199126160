<template>
	<br><button class="mdc-button" :disabled="itemVisible<1" v-on:click="showPrevious"><i class="material-icons mdc-button__icon" aria-hidden="true">chevron_left</i></button><button class="mdc-button" :disabled="itemVisible>=caruselItems.length-1" v-on:click="showNext"><i class="material-icons mdc-button__icon" aria-hidden="true">chevron_right</i></button>
	<div v-for="(item , index) in caruselItems"><div v-show="itemVisible==index"><slot v-bind:media="item" name="carousel"></slot></div></div>
</template>

<script>
	// <script lang="ts">
	import $ from 'jquery';
	// see de.vdoemming.blog.vue.components.common.CarouselVC
	export default {
		props: {
			p_vueDomain: {
						type: String,
						required: false
					},
			caruselItems: {
						type: Array,
						required: true,
						default: []
					},
			ddeebbuugg: {
						type: Object,
						required: false,
						default: {}
					},
			p_instanceId: {
						type: String,
						required: false
					}
		}
		,
		data() {
			return {"itemVisible":0}
		}
		,
		computed: {
			c_vueDomain() {
				if(this.p_vueDomain==undefined){
					return 'CarouselVC'
				}else{
					return this.p_vueDomain
				}
			}
			,
			console() {
				return console
			}
		}
		,
		methods: {
			i18n: function(key){
				var result=this.globalI18nDict[key];
				if(result===undefined){
					return key
				}else{
					return result
				}
			}
			,
			showNext: function(){
				if(this.itemVisible<this.caruselItems.length-1){
					this.itemVisible++
				}
			}
			,
			showPrevious: function(){
				if(this.itemVisible>0){
					this.itemVisible--
				}
			}
		}
		,
		mounted() {
			const thisVue=this
			
			console.log('mounted component on vue domain: '+this.c_vueDomain)
		}
		,
		provide() {
			return {}
		}
	}
</script>
